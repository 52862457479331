import {
  Container,
  CssBaseline,
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
  Link,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAuthToken,
  getCookieByName,
  getErrorMessage,
  setCookieByName,
} from "../../utils/util";
import {
  IUser,
  getLicenseAvailable,
  getLoggedInUserDetails,
  loginUser,
} from "../../API/services";
import {
  Controller,
  FieldValues,
  FormProvider,
  useForm,
  useFormState,
} from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import GlobalLoader from "../../API/useAxiosLoader";
import { useEffect, useState } from "react";
import LoginAlert from "./LoginAlert";
import logo from "../../assets/noshadow.png";
import publicAllowedUrlPaths from "../../routes/publicPaths";
import isEmpty from "lodash-es/isEmpty";
import { PollingHandler } from "../../utils/pollingHandler";
import { useNotificationStore } from "../../store/NotificationStore";
import { success } from "../Notification/NotificationMessages";
import { SubscriptionDTO, UserDetailsDTO } from "../../utils/Types/DTO";
import { useSubscriptionStore } from "../../store/SubscriptionStore";

const Login = () => {
  const navigate = useNavigate();
  const setNotification = useNotificationStore(
    (state) => state.setNotification
  );
  const authToken = getAuthToken();
  const location = useLocation();
  const [errorElement, setErrorElement] = useState<React.ReactElement>();

  // useEffect(() => {
  //   console.log(authToken);
  //   if (isEmpty(authToken)) {
  //     if (publicAllowedUrlPaths.includes(location.pathname) && isEmpty(authToken)) {
  //       console.log('No session Found');
  //       navigate('/login');
  //     }
  //   }
  // }, [location.pathname]);

  const validationSchema = object().shape({
    username: string().required("Email is required").email("Email is invalid"),
    password: string()
      .min(6, "Password must be at least 4 characters")
      .required("Password is required"),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const methods = useForm(formOptions);

  const { handleSubmit } = methods;

  const onFormSubmit = (data: FieldValues) => {
    console.log(data);
    if (!isEmpty(data.username) && !isEmpty(data.password)) {
      const payload: IUser = {
        email: data.username,
        password: data.password,
        overrideExistingToken: false,
      };
      onSubmit(payload);
    }

    // if(data.get('email')=='hr@tech.com' && data.get('password')!='')
    // {
    //   setCookieByName('Authorization','hr',1);
    //   navigate("/home");
    // }
    // if(data.get('email')=='manager@tech.com' && data.get('password')!='')
    // {
    //   setCookieByName('Authorization','manager',1);
    //   navigate("/home");
    // }
    // if(data.get('email')=='admin@tech.com' && data.get('password')!='')
    // {
    //   setCookieByName('Authorization','admin',1);
    //   navigate("/home");
    // }
    // if(data.get('email')=='user@tech.com' && data.get('password')!='')
    // {
    //   setCookieByName('Authorization','user',1);
    //   navigate("/home");
    // }
  };

  const overRideToken = (data: IUser) => {
    data.overrideExistingToken = true;
    onSubmit(data);
  };

  const onSubmit = (payload: IUser) => {
    loginUser({
      ...payload,
      overrideExistingToken: payload.overrideExistingToken,
    })
      .then(async (response) => {
        if (response.data) {
          setCookieByName("loggedInUser", payload.email, 0);
          setCookieByName("userId", response.data.userId, 0);
          setCookieByName("Authorization", response.data.authorization, 0);

          const loggedInUserDetails = await getLoggedInUserDetails(
            getCookieByName("userId") ?? ""
          );
          setCookieByName("SessionID", response.data.sessionID, 0);
          setCookieByName("ROLE", loggedInUserDetails.data?.role, 0);
          setCookieByName(
            "PERMISSIONS",
            loggedInUserDetails.data?.permissions,
            0
          );
          setCookieByName(
            "ACCOUNT_OWNER",
            loggedInUserDetails.data?.organizationOwner,
            0
          );

          // setCookieByName("loggedInUserID",)

          if (loggedInUserDetails.data?.role === "SUPER_USER") {
            console.log("Navigating to home for SUPER_USER");
            navigate("/home");
          } else {
            const { checkSubscriptionStatus } = useSubscriptionStore.getState();
            await checkSubscriptionStatus(loggedInUserDetails.data);
            const { hasActiveSubscription } = useSubscriptionStore.getState();

            if (hasActiveSubscription) {
              navigate("/home");
            } 
            // else {
            //   navigate("/subscription-expired");
            // }
          }

          setNotification(success("User Authenticated Successfully"));
        }
      })
      .catch((error) => {
        setErrorElement(
          <LoginAlert
            loginParam={payload}
            statusCode={error.response.status}
            overRideToken={overRideToken}
          />
        );
      });
  };

  // const checkSubscriptionStatus = async (
  //   user: UserDetailsDTO
  // ): Promise<string> => {
  //   console.log("Checking subscription status...");
  //   console.log(user);

  //   const organizationIds = Object.keys(user.organizationMap);

  //   // Loop through each organization ID to check for active subscriptions
  //   for (const orgId of organizationIds) {
  //     try {
  //       const response = await getLicenseAvailable(orgId);
  //       const data = response.data;

  //       // Check if there's an active subscription in esatSubscriptions
  //       if (data.esatSubscriptions && data.esatSubscriptions.length > 0) {
  //         const activeSubscription = data.esatSubscriptions.some(
  //           (subscription: SubscriptionDTO) => subscription.status === "active"
  //         );

  //         // Return dashboard route if active subscription is found
  //         if (activeSubscription) {
  //           return "/home";
  //         }
  //       }
  //     } catch (error) {
  //       console.error(`Failed to check subscription for org ${orgId}:`, error);
  //     }
  //   }

  //   // If no active subscription was found, route based on user role
  //   return user.organizationOwner ? "/home" : "/subscription-expired";
  // };
  const formState = useFormState({
    control: methods.control,
  });

  return (
    <>
      {/* <GlobalLoader /> */}

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="Logo"
            style={{ marginRight: "16px", height: "40px", width: "200px" }}
          />
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <FormProvider {...methods}>
            <Box
              component="form"
              onSubmit={handleSubmit(onFormSubmit)}
              sx={{ mt: 1 }}
            >
              <Controller
                name="username"
                control={methods.control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    fullWidth
                    id="username"
                    type="email"
                    label="Email Address"
                    name="username"
                    autoComplete="email"
                    required
                    autoFocus
                    error={!!formState.errors.username}
                    helperText={
                      formState.errors.username
                        ? formState.errors.username.message
                        : ""
                    }
                  />
                )}
              />
              <Controller
                name="password"
                control={methods.control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    required
                    autoComplete="current-password"
                    error={!!formState.errors.password}
                    helperText={
                      formState.errors.password
                        ? formState.errors.password.message
                        : ""
                    }
                  />
                )}
              />
              {errorElement && errorElement}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate("/forgot-password")}
                    variant="body2"
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link sx={{ cursor: 'pointer' }} onClick={() => navigate("/entity/public/register")} variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </FormProvider>
        </Box>
      </Container>
    </>
  );
};
export default Login;
