import { Container, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AvgBarChart from "../components/Graphs/BarCharts/AvgBarChart";
import CountCard from "../HrDashboard/Components/CountCard";
import { useEffect, useState } from "react";
import { getSkillsAverage, getUsersCount } from "../API/services";
import { getAuthToken, getCookieByName, getLoggedInUser, getRoleToken } from "../utils/util";
import { PollingHandler } from "../utils/pollingHandler";
import { AvgSkillScoreItemDTO } from "../utils/Types/DTO";
import AvgPieChart from "../components/Graphs/PieCharts/AvgPieChart";
import { useUserContext } from "../context/UserContext";


export interface usersCountType{
    role: string;
    count: number;
}
const Home=()=>{
    const navigate = useNavigate();
    const user = useUserContext();
    const authToken = getAuthToken();
    const getRole=getRoleToken();
    const [polling, setPolling] = useState<boolean>(false);
    const getLoggedInUserEmail = getLoggedInUser();
    const [usersCount, setUsersCount] = useState < usersCountType[]>();
    const [avgScores, setAvgScores] = useState < AvgSkillScoreItemDTO[]>();

    
    useEffect(() => {
        const fetchCount = async () => {
            if(user?.id && Object.keys(user?.organizationMap).length > 0){
                const orgID = Object.keys(user.organizationMap)[0];
                if (getLoggedInUserEmail && orgID) {
                    const response = await getUsersCount(orgID ?? '');
                    const avgScoreResponse = await getSkillsAverage(orgID ?? '');
                    if (response.data) setUsersCount(response.data);
                    if (avgScoreResponse.data) setAvgScores(avgScoreResponse.data);
                    // const avgData = await getSkillsAverage(getLoggedInUserEmail);
                }
            }
        };
        fetchCount();
    }, [user?.id]);

    useEffect(() => {
        const initPolling = () => {
            setPolling(true);
        };

        initPolling();

    }, [authToken]);

    return(
        <Container component={"main"}>
            {
                polling && (
                    <PollingHandler />
                )

            }
            <Paper elevation={3} style={{ padding: '20px', margin: '10px' }}>
                <Grid container spacing={3}>
                    {
                       getRole=='ADMINISTRATOR'&&(
                       usersCount && (
                            usersCount.map((user) => (
                                <>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <CountCard count={user.count} bottomText={`${user.role}(s)`} />
                                    </Grid>

                                </>
                            ))
                            
                        )  
                         )
                    }
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <CountCard onClickHandler={()=>navigate("/users")} count={100} bottomText={'Employees'}/>
                         </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <CountCard onClickHandler={()=>navigate("/supervisors")} count={20} bottomText={'Supervisors'}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <CountCard onClickHandler={()=>navigate("/supervisors")} count={5} bottomText={'Admin'}/>
                        </Grid> */}
                
                    </Grid>
                    
                    <Grid marginTop={2}>
                        {
                            avgScores&&( <AvgBarChart title={"Aggregate Score of All Applicants"} scoreData={avgScores} />)
                        }

                        {
                           avgScores&&( <AvgPieChart title={"Aggregate Score of All Applicants"} scoreData={avgScores} />)  
                        }
                       
                    </Grid> 
                    
            </Paper>
        </Container>
    );
};
export default Home;