import React from 'react';
import { Button } from '@mui/material';

const DownloadButton: React.FC = () => {
  const handleDownload = () => {
    // Construct the URL for the file in the public/assets folder
    const fileUrl = `${process.env.PUBLIC_URL}/example.csv`;
    
    // Create a temporary anchor element and trigger a click to download the file
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'example.csv'; // Name of the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button onClick={handleDownload} variant="text" sx={{
        bgcolor: '#fff', color: '#000', '&:hover': {
          backgroundColor: '#fff',
        }
      }} >
      Download Sample File
    </Button>
  );
};

export default DownloadButton;
