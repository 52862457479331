import AddEntity from "../components/addEntity/AddEntity"
import AddUser from "../components/AddUser/AddUser"
import Assessment from "../components/Assesment/Assesment"
import AssessmentHistory from "../components/Assesment/AssessmentHistory"
import AddPromo from "../components/Assesment/licenseManagement/AddPromo"
import Home from "../Dashboard/Home"
import HRHome from "../HrDashboard/HRHome"
import OrgListing from "../Listing/OrgListing/OrgListing"
import SupervisorDetails from "../Listing/SupervisorListing/SupervisorDetails"
import SupervisorListing from "../Listing/SupervisorListing/SupervisorListing"
import UserListing from "../Listing/UserListing/UserListing"
import AddAssessment from "../components/Assesment/licenseManagement/AddAssessment"
import AddSupervisors from "../components/addSupervisors/AddSupervisors"
import AssessmentSelectionComponent from "../components/Assesment/consumeLicense/preScreen"
import TestTakersComponent from "../components/Assesment/consumeLicense/assessmentTakers"
import ReviewAndSubmitComponent from "../components/Assesment/consumeLicense/assessmentReview"
import UserHome from "../Dashboard/UserHome"
import CandidatesList from "../HrDashboard/CandidateScores/CandidatesList"
import PasswordChange from "../components/password/PasswordChange"
import MyProfile from "../components/MyProfile/MyProfile"
import Teachables from "../components/Teachables/Teachables"
import AddAdmin from "../components/addAdmin/AddAdmin"
import AddSkill from "../components/addSkill/AddSkill"
import PaymentPricePage from "../components/Payment/Components/PaymentPricePage"
import UserDefinedSkills from "../components/UserDefinedSkills/UserDefinedSkills"
import UpdateSupervisor from "../components/addSupervisors/updateSupervisors"
import AdminListing from "../Listing/AdminListing/AdminListing"
import AdminListingByOrg from "../Listing/AdminListing/AdminListingByOrg"
import { SuperAdminDashBoard } from "../Dashboard/SuperAdmin"
import { AssessmentDashboard } from "../components/Assesment/AssessmentDashboard"
import { URLS } from "../constants"
import { DashboardWithTwoCards } from "../Dashboard/DashboardWithTwoCards"
import { DashboardWithCardAndList } from "../Dashboard/DashboardWithCardAndList"
import EditOrganization from "../components/editEntity/EditOrganizationForm"
import SubscriptionExpired from "../components/Payment/SubscriptionExpired"
import RespondentsEntry from "../components/Assesment/consumeLicense/respondentsEntry"
import BatchJobList from "../components/BulkUpload/BatchJob"
import { elements } from "chart.js"
import AvailableLicenseDetailsCard from "../components/Payment/Components/MyPlan"

export const ProtectedRoutes = [
  {
    path: "/assessment/:assessmentId/details",
    element: <Assessment />,
  },
  {
    path: "/assessment/history/:candidateEmail",
    element: <AssessmentHistory />,
  },
  {
    path: "/assessment/assign",
    element: <AssessmentSelectionComponent />,
  },
  {
    path: "/assessment/assign/respondents",
    element: <RespondentsEntry />,
  },
  {
    path: "/assessment/assign/respondents/review",
    element: <ReviewAndSubmitComponent />,
  },
  {
    path:"/batch-job-list",
    element:<BatchJobList/>
  },
  {
    path: "/users/add",
    element: <AddUser />,
  },
  {
    path: "/users/",
    element: <UserListing />,
  },
  {
    path: "hr/applicants/",
    element: <CandidatesList />,
  },
  {
    path: "/supervisors/",
    element: <SupervisorListing />,
  },
  {
    path: "/supervisors/:supervisorID/details",
    element: <SupervisorDetails />,
  },
  {
    path: "/supervisors/add",
    element: <AddSupervisors />,
  },
  {
    path: "/supervisors/update/:supervisorId",
    element: <UpdateSupervisor />,
  },
  {
    path: "/*",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/hr/users/",
    element: <UserListing />,
  },
  {
    path: "/hr/home",
    element: <HRHome />,
  },
  {
    path: "/update-password",
    element: <PasswordChange />,
  },
  {
    path: "/profile",
    element: <MyProfile />,
  },
  {
    path: "/minimum-scores",
    element: <UserDefinedSkills />,
  },
  {
    path: "/screen-buy",
    element: <PaymentPricePage />,
  },
  {
    path:"/available-licenses",
    element:<AvailableLicenseDetailsCard/>
  },
  {
    path: "/add/admin",
    element: <AddAdmin />,
  },
  {
    path: "/userdefinedskills",
    element: <UserDefinedSkills />,
  },
  {
    path: "/applicants/",
    element: <UserListing />,
  },
  {
    path:"/subscription-expired",
    element:<SubscriptionExpired/>,
  },
  {
    path: "/entity/edit/:organizationId",
    element: <EditOrganization />,
  }
]
export const SuperAdminProtectedRoutes = [
  {
    path: "/assessment/:assessmentId/details",
    element: <Assessment />,
  },
  {
    path: "/assessment/history/:candidateEmail",
    element: <AssessmentHistory />,
  },
  {
    path: "/hr/applicants/",
    element: <CandidatesList />,
  },
  {
    path: "/assessment/management/add/:orgId",
    element: <AddAssessment />,
  },
  {
    path: "/org",
    element: <OrgListing />,
  },

  {
    path: "/users/add",
    element: <AddUser />,
  },
  {
    path: "/applicants/",
    element: <UserListing />,
  },
  {
    path: "/supervisors/",
    element: <SupervisorListing />,
  },
  {
    path: "/supervisors/add/:organizationId",
    element: <AddSupervisors />,
  },
  {
    path: "/supervisors/org/:organizationId",
    element: <SupervisorListing />,
  },
  {
    path: "/supervisors/:supervisorID/details",
    element: <SupervisorDetails />,
  },
  {
    path: "/supervisors/update/:supervisorId",
    element: <UpdateSupervisor />,
  },
  {
    path: "/*",
    element: <SuperAdminDashBoard />,
  },
  {
    path: "/home",
    element: <SuperAdminDashBoard />,
  },
  {
    path: "/entity/add",
    element: <AddEntity/>,
  },
  {
    path: "/add/admin/:organizationId",
    element: <AddAdmin />,
  },
  {
    path: URLS?.edit_entity,
    element: <EditOrganization />,
  },
  {
    path: "/license/add-promo",
    element: <AddPromo />,
  },
  {
    path: "/update-password",
    element: <PasswordChange />,
  },
  {
    path: "/profile",
    element: <MyProfile />,
  },
  {
    path: "/admin/list",
    element: <AdminListing />,
  },
  {
    path: "/admin/list/org/:organizationId",
    element: <AdminListingByOrg />,
  },
  {
    path: URLS?.assessment?.dashboard,
    element: <AssessmentDashboard />,
  },
  {
    path: URLS?.assessment?.avg_assessment_score,
    element: <DashboardWithTwoCards />,
  },
  {
    path: URLS?.assessment?.total_employees,
    element: <DashboardWithCardAndList showViewCourseAction={true} />,
  },
  {
    path: URLS?.assessment?.screen_assessment_details,
    element: <CandidatesList />,
  },
  {
    path: URLS?.assessment?.total_applicants,
    element: <DashboardWithCardAndList showViewCourseAction={false} />,
  },
  {
    path: URLS?.assessment?.grow_assessments,
    element: <DashboardWithCardAndList showViewCourseAction={true} />,
  },
  {
    path: URLS?.assessment?.avg_grow_assessment_score,
    element: <DashboardWithCardAndList showViewCourseAction={false} />,
  },
  {
    path: URLS?.assessment?.avg_screen_assessment_score,
    element: <DashboardWithCardAndList showViewCourseAction={true} />,
  },
]
export const UserProtectedRoutes = [
  {
    path: "/assessment/:assessmentId/details",
    element: <Assessment />,
  },
  {
    path: "/assessment",
    element: <AssessmentHistory />,
  },
  {
    path: "/assessment/history/:candidateEmail",
    element: <AssessmentHistory />,
  },

  {
    path: "/*",
    element: <UserHome />,
  },
  {
    path: "/home",
    element: <UserHome />,
  },
  {
    path: "/update-password",
    element: <PasswordChange />,
  },
  {
    path: "/profile",
    element: <MyProfile />,
  },
  {
    path: "/courses",
    element: <Teachables />,
  },
  {
    path:"/subscription-expired",
    element:<SubscriptionExpired/>,
  },
]

export const HRProtectedRoutes = [
  {
    path: "/assessment/:assessmentId/details",
    element: <Assessment />,
  },
  {
    path: "/users/",
    element: <UserListing />,
  },
  {
    path: "hr/applicants/",
    element: <CandidatesList />,
  },
  {
    path: "hr/home",
    element: <HRHome />,
  },
  {
    path: "/*",
    element: <HRHome />,
  },
  {
    path: "update-password",
    element: <PasswordChange />,
  },
  {
    path: "/profile",
    element: <MyProfile />,
  },
  {
    path:"/subscription-expired",
    element:<SubscriptionExpired/>,
  },
]
