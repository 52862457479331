import { useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { getAssessmentDetailsWithCategories } from "../../API/services"
import {
  capitalizer,
  formatDate,
  getLoggedInUser,
  getRoleToken,
  convertToCapitalizedWords,
} from "../../utils/util"
import { AssessmentsWithCategoriesDTO } from "../../utils/Types/DTO"
import {
  Container,
  CssBaseline,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Chip,
  styled,
  Stack,
  Box,
  Typography,
} from "@mui/material"
import TitleBanner from "../../components/TitleBanner/TitleBanner"
import { useUserContext } from "../../context/UserContext"
import OptionDropDown from "../../components/OptionsList/optionsDropDown"
import { useSuperAdminStore } from "../../store/SuperAdminStore"
type props = {
  bgColor: string
}
export const StyleChip = styled(Chip)(({ bgColor }: props) => ({
  backgroundColor: bgColor,
  borderRadius: 3,
}))
const CandidatesList = ({ showViewCourseAction }: any) => {
  const organizationList = useSuperAdminStore((state) => state?.orgList)
  const user = useUserContext()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const getLoggedInUserEmail = getLoggedInUser()
  const isSuperAdmin = "SUPER_USER" === getRoleToken()
  const category = searchParams?.get("category")
  const typoOfAssessment = searchParams?.get("assessment")
  const orgId = location?.state?.orgId
  const selectedOrgName: string =
    organizationList?.find((org: any) => org?.id == orgId)?.organizationName ||
    ""
  const [filter, setFilter] = useState<any>(category || "")
  const [selectedOption, setSelectedOption] = useState<string>(selectedOrgName)
  const [assessmentData, setAssessmentData] = useState<
    AssessmentsWithCategoriesDTO[]
  >([])

  const handleSelectOrg = (event: any) => {
    setSelectedOption(event?.target?.value)
  }

  const fetchData = async () => {
    if (isSuperAdmin) {
      const selectedOrgID: any = orgId
        ? orgId
        : organizationList?.find(
            (item: any) => item?.organizationName == selectedOption
          )?.id
      const response = await getAssessmentDetailsWithCategories(
        selectedOrgID ?? ""
      )
      if (response?.data) setAssessmentData(response.data)
    } else {
      if (user.id) {
        const orgID = Object.keys(user?.organizationMap)?.[0]
        if (getLoggedInUserEmail) {
          const response = await getAssessmentDetailsWithCategories(orgID)
          if (response.data) setAssessmentData(response.data)
        }
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [user.id])

  // Get unique evaluation types for the dropdown
  const evaluationOrder = [
    "ALL_EVALUATIONS",
    "HIGHLY_RECOMMENDED",
    "RECOMMENDED",
    "POSSIBLE",
    "NOT_RECOMMENDED",
  ];
  
  const evaluationTypes = [
    "ALL_EVALUATIONS",
    ...Array.from(new Set(assessmentData?.map((a) => a.evaluation))).sort(
      (a, b) => evaluationOrder.indexOf(a) - evaluationOrder.indexOf(b)
    ),
  ];

  const filteredAssessments = useMemo(() => {
    const filteredByAssessment = typoOfAssessment
      ? assessmentData?.filter(
          (a: any) => a?.assessmentType == typoOfAssessment
        )
      : assessmentData
    return filter == "ALL_EVALUATIONS" || !filter
      ? filteredByAssessment
      : filteredByAssessment?.filter((a) => a?.evaluation === filter)
  }, [filter, assessmentData])

  return (
    <Stack sx={{ flexDirection: "row" }}>
      {isSuperAdmin && (
        <OptionDropDown
          onSearchClickCB={fetchData}
          selectedOption={selectedOption}
          handleOnChange={handleSelectOrg}
          setSelectedOption={setSelectedOption}
        />
      )}
      <Container component="main">
        <CssBaseline />
        <TitleBanner title={"Applicant List"} />
        <Box sx={{ m: "5px 0" }}>
          <select value={filter} onChange={(e) => setFilter(e.target.value)}>
            {evaluationTypes?.map((type: any, index: number) => (
              <option key={index} value={type}>
                {convertToCapitalizedWords(type)}
              </option>
            ))}
          </select>
        </Box>
        <TableContainer component={Paper} sx={{ minWidth: "65vw" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Average Score</TableCell>
                <TableCell>Top Skill</TableCell>
                <TableCell>Ranking</TableCell>
                <TableCell>Date of Assessment</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAssessments &&
                filteredAssessments
                  ?.sort((a: any, b: any) => b?.overallScore - a?.overallScore)
                  ?.map((assessment: any) => (
                    <TableRow key={assessment.uniqueAssessmentId}>
                      <TableCell>{assessment.name}</TableCell>
                      <TableCell>
                        <StyleChip
                          label={assessment.overallScore}
                          bgColor={"#f7fcd1"}
                        />
                      </TableCell>
                      <TableCell>
                        {assessment.topSkill
                          ? capitalizer(assessment.topSkill)
                          : ""}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {convertToCapitalizedWords(assessment.evaluation)}
                      </TableCell>
                      <TableCell>{formatDate(assessment.dateTime)}</TableCell>
                      <TableCell align="right">
                        <Stack gap={1} alignItems={"flex-end"}>
                          <Button
                            onClick={() =>
                              navigate(
                                `/assessment/${btoa(
                                  assessment.uniqueAssessmentId
                                )}/details`
                              )
                            }
                            variant="outlined"
                            size="small">
                            View Assessment
                          </Button>
                          {showViewCourseAction && (
                            <Button variant="outlined" size="small">
                              View Courses
                            </Button>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          {filteredAssessments?.length < 1 && (
            <>
              <Typography variant="body1" sx={{ textAlign: "center", p: 1 }}>
                No items match your criteria. Try a different filter.
              </Typography>
            </>
          )}
        </TableContainer>
      </Container>
    </Stack>
  )
}

export default CandidatesList
