
import React, { useEffect, useState } from 'react';
import { Box,  Button,  Container, CssBaseline, Grid, Paper, Typography } from '@mui/material';
import logo from '../../../assets/ESATScreenLogo.png';
import { cancelSubscription, createCheckoutSession, getLicenseAvailable } from '../../../API/services';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PaymentSuccessDialog from './PaymentSuccessDialouge';
import { getCookieByName, openInNewTab } from '../../../utils/util';
import { LicenseDTO, SubscriptionDTO } from '../../../utils/Types/DTO';
import { useUserContext } from '../../../context/UserContext';
import LicenseDetailsCards from '../Subscription/LicenseDetailsCards';
import { useNotificationStore } from '../../../store/NotificationStore';
import { success, warning } from '../../Notification/NotificationMessages';
import TitleBanner from '../../TitleBanner/TitleBanner';



interface PlanProps {
  title: string;
  plan: {
    price: number,
    quantity: number,
    apiId: string,
  }[];
  createCheckout: (id: string, quantity: number) => void;
  isAnnulPriceList?:boolean
}
export interface LicenseType {
  esatLicenses: LicenseDTO[],
  esatSubscriptions: SubscriptionDTO[]
};
const response = {
  "esatLicenses": [
      {
          "id": "466062f9-6975-4d63-b9ff-9f81ed03b403",
          "assessmentTitle": "ESAT™ Screen",
          "assessmentCode": "ASMT-20824",
          "availableLicenses": 31,
          "organizationId": "f1b1d5eb-daba-4a1f-abda-f4805cc8bd98",
          "totalLicensesUsed": 4,
          "createdDateTime": "2024-01-26T14:53:06.232993Z",
          "lastUpdatedDateTime": "2024-01-31T11:07:46.171473Z"
      },
      {
          "id": "23ee3967-5a79-4c1b-8662-d462f70801e5",
          "assessmentTitle": "ESAT™ Grow",
          "assessmentCode": "ASMT-20827",
          "availableLicenses": 4,
          "organizationId": "f1b1d5eb-daba-4a1f-abda-f4805cc8bd98",
          "totalLicensesUsed": 0,
          "createdDateTime": "2024-01-21T12:11:19.093456Z",
          "lastUpdatedDateTime": "2024-01-21T12:11:19.093456Z"
      }
  ],
  "esatSubscriptions": [
      {
          "subscriptionId": "sub_1OcvrMDTPIE8oHtkw6sRca6t",
          "status": "active",
          "productId": "ASMT-20824",
          "startDate": "2024-02-02T19:46:14Z",
          "endDate": "2024-03-02T19:46:14Z",
          "createdDateTime": "2024-01-26T14:53:10.788Z",
          "lastUpdatedDateTime": "2024-01-26T14:53:10.788Z"
      }
  ]
}
const monthlyPlan = [{
  price: 133,
  quantity: 10,
  apiId: 'price_1QduVXDl1qSPxCGX7BQSXJQS',
}, {
  price: 266,
  quantity: 25,
  apiId: 'price_1QduVXDl1qSPxCGXP5s6C6di',
}, {
  price: 399,
  quantity: 50,
  apiId: 'price_1QduVXDl1qSPxCGXAahMrdz8',
}, {
  price: 667,
  quantity: 100,
  apiId: 'price_1QduVXDl1qSPxCGX5yOpedg3',
}, {
  price: 2667,
  quantity: 500,
  apiId: 'price_1QduVXDl1qSPxCGXHg68nxvI',
}, {
  price: 3999,
  quantity: 1000,
  apiId: 'price_1QduVXDl1qSPxCGXuh2Orpyk',
}, {
  price: 8331,
  quantity: 2500,
  apiId: 'price_1QduVXDl1qSPxCGXbZlA1fOM',
}]

const annualPlan = [{
  price: 100,
  quantity: 10,
  apiId: 'price_1QduViDl1qSPxCGXDQIcaK95',
}, {
  price: 200,
  quantity: 25,
  apiId: 'price_1QduViDl1qSPxCGXfJ1ss0NR',
}, {
  price: 300,
  quantity: 50,
  apiId: 'price_1QduViDl1qSPxCGXh2WpRQ2b',
}, {
  price: 500,
  quantity: 100,
  apiId: 'price_1QduViDl1qSPxCGXlK1qQEed',
}, {
  price: 2000,
  quantity: 500,
  apiId: 'price_1QduViDl1qSPxCGXp9OF4WWX',
}, {
  price: 3000,
  quantity: 1000,
  apiId: 'price_1QduViDl1qSPxCGX0hL0RZtR',
}, {
  price: 6250,
  quantity: 2500,
  apiId: 'price_1QduViDl1qSPxCGXIsVtJh4c',
}]

const PricingCard: React.FC<PlanProps> = ({ title, plan, createCheckout,isAnnulPriceList=false }) => (
  <Grid item xs={12} sm={6} md={6}>
    <Paper elevation={3} style={{ padding: '12px', marginBottom: '20px' }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold' }} align="center" gutterBottom>
        {title}
      </Typography>
      {plan.map((data, index) => (
        <Box key={index} onClick={() => createCheckout(data.apiId, data.quantity)} textAlign="left" sx={{
          cursor: 'pointer', padding: 1, '&:hover': {
            backgroundColor: '#e0e0e0',  // Background color on hover
          },
        }}>
          <Typography variant="h6" color="primary">
            {data.quantity} assessments per month
          </Typography>
          <Typography variant="body1" color="textSecondary">
           {isAnnulPriceList?` $ ${data.price*12}`:`$ ${data.price}/month`}
          </Typography>
        </Box>
      ))}
    </Paper>
  </Grid>
);

const PaymentPricePage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const isSuccess = searchParams.get('success');
  const userId = getCookieByName('userId');
  const user = useUserContext();
  const [licenses, setLicenses] = useState<LicenseType>({ esatLicenses: [], esatSubscriptions: [] });
  const setNotification = useNotificationStore((state) => state.setNotification);
  useEffect(() => {
    setOpen(isSuccess ? true : false);
  }, [])

useEffect(() => {
    const fetchLicenses = async () => {
      const orgId = Object.keys(user.organizationMap)[0];
      if (orgId) {
        const response = await getLicenseAvailable(orgId);
        setLicenses(response.data);
        console.log(response);
      }
    
    };
    fetchLicenses();

},[]);

  const createCheckout = async (id: string, quantity: number) => {
    const orgId = Object.keys(user.organizationMap)[0];
    if (userId && orgId) {
      const url = await createCheckoutSession(orgId, userId, id, quantity.toString());
      window.location.href = url.data;
    }
  }
  const cancelSubscriptionAction = async (subscriptionId:string) => { 
    console.log(subscriptionId);
    try {
      const response = await cancelSubscription(subscriptionId);
      setNotification(success('Subscription Cancelled Successfully'));
      navigate(-1);
    }
    catch (error) {
      setNotification(warning('Failed to cancel subscription'));
      console.log(error);
    }
    
  }


  return (
    <Container component="main" >
      <CssBaseline />

      {
        licenses.esatSubscriptions.length  >0 ?(
          <>
        {/* <TitleBanner title="Your Subscription" /> */}
        <Grid container alignContent={"center"} spacing={2}>
        <LicenseDetailsCards esatLicenses={licenses.esatLicenses} esatSubscriptions={licenses.esatSubscriptions} onCancel={cancelSubscriptionAction}/>
        
        </Grid>
        </>
   
        ):( 
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={logo} alt="Logo" style={{ marginLeft: '24px', height: '40px', width: '200px' }} />
        <Typography variant="h4" align="center" gutterBottom mt={2}>
          Our Pricing
        </Typography>
        <Grid container spacing={3} mt={2}>
          <PricingCard title="Monthly Plans" plan={monthlyPlan} createCheckout={createCheckout} />
          <PricingCard title="Yearly Plans" plan={annualPlan} createCheckout={createCheckout} isAnnulPriceList/>
        </Grid>
        <Typography mt={3} variant="body2" align="center" color="textSecondary">
          Custom Plans: Need something more tailored to your needs? Contact us for custom enterprise solutions.
        </Typography>

      </Box>)
      }
     
      <PaymentSuccessDialog open={open} onClose={() => setOpen(false)} />
    </Container>
  );

}

export default PaymentPricePage;