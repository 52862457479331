import { AxiosProgressEvent, AxiosResponse } from "axios";
import axios from "../../../axios";

const contextPath='/batch';
// export const uploadBulkRegistration = (file: File,userId:string,assessmentId:string ) => {
//     const formData = new FormData();
//     formData.append('file', file);
  
//     return axios.post(
//       `/batch/bulk-registration/upload?userId=${userId}&assessmentId=${assessmentId}`,
//       formData,
//       {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       }
//     );
//   };

// Bulk registration CSV upload API call with dynamic parameters and progress callback support
export const uploadBulkRegistration = (
    file: File,
    userId: string,
    assessmentId: string,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): Promise<AxiosResponse<{ operationId: string; message: string }>> => {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(
      `/batch/bulk-registration/upload?userId=${userId}&assessmentId=${assessmentId}`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress
      }
    );
  };

export const getBatchJobList = ()=> axios.get(`${contextPath}/bulk-registration/report`);

export const getBatchJobStatus = (batchID:string) => axios.get(`${contextPath}/bulk-registration/report/${batchID}`);