import axios from "../../../axios";
import IUser from "../types/IUser";
import PasswordDTO from "../types/PasswordDTO";

export const loginUser = (userDTO: IUser) => axios.post(`/auth/authenticate`, userDTO);

export const refreshToken = () => axios.post(`auth/refreshToken`);

export const logoutUserCall = (sessionID: string) => axios.get(`/auth/logout`, {
    params: {
        sessionID: sessionID,
    },
});

export const changePassword=(payload:PasswordDTO)=>axios.post(`/auth/passwordReset`,payload);

