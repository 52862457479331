import { Container, TextField, Button, List, ListItem, ListItemText, IconButton, Card, CardContent, Paper } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import TitleBanner from '../../TitleBanner/TitleBanner';
import { isEmpty } from 'lodash-es';
import { useNotificationStore } from '../../../store/NotificationStore';
import { success, warning } from '../../Notification/NotificationMessages';



const TestTakersComponent: React.FC = () => {
    const setNotification = useNotificationStore((state) => state.setNotification);
    const [respondent, setRespondent] = useState<{ email: string; firstname: string; lastname: string; confirm_email:string }>({
        email: '',
        firstname: '',
        lastname: '',
        confirm_email: ''
    });
    const [respondents, setRespondents] = useState<Array<{ email: string; firstname: string; lastname: string }>>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const selectedAssessment = location.state?.assessmentId;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setRespondent((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleAddRespondent = () => {
        if(respondent.email !== respondent.confirm_email){
            setNotification(warning('Email and Confirm Email should be same!'));
            return;
        }
        if (respondents.some(res => res.email === respondent.email)) {
            // Handle the case where the email is already in the respondents list.
            // You can show an alert or a notification to the user.
            setNotification(warning('A respondent with this email already exists!'));
           // alert("A respondent with this email already exists!");
            return;
        }
        setRespondents((prev) => [...prev, respondent]);
        setRespondent({
            email: '',
            firstname: '',
            lastname: '',
            confirm_email: ''
        });
    };

    const handleDeleteRespondent = (index: number) => {
        setRespondents((prev) => prev.filter((_, i) => i !== index));
    };

    const handleNext = () => {
        navigate('/assessment/assign/respondents/review', { state: { assessmentId: selectedAssessment, respondents } });
    };

    return (
        <Container>
            {/* <TitleBanner title={"Add Respondents | Applicants"} /> */}
            <Paper elevation={3} style={{ margin: '0 auto', padding: '20px' }}>
                <TextField
                    name="firstname"
                    label="First Name"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={respondent.firstname}
                    onChange={handleChange}
                />

                <TextField
                    name="lastname"
                    label="Last Name"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={respondent.lastname}
                    onChange={handleChange}
                />

                <TextField
                    name="email"
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={respondent.email}
                    onChange={handleChange}
                />
                 <TextField
                    name="confirm_email"
                    label="Confirm Email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={respondent.confirm_email}
                    onChange={handleChange}
                />

                <Button variant="contained" color="primary" onClick={handleAddRespondent}>
                    Add Respondent
                </Button>
            </Paper>

            {/* Display List of Respondents with Delete Option */}
            {
                !isEmpty(respondents) && (
                    <>
                        <Paper elevation={3} style={{ marginTop:4, margin: '0 auto', padding: '20px' }}>
                            <List>
                                {respondents.map((res, index) => (
                                    <ListItem key={index}>
                                        <Card variant="outlined" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <CardContent style={{ flex: 1 }}>
                                                <ListItemText primary={res.firstname + ' ' + res.lastname} secondary={res.email} />
                                            </CardContent>
                                            <IconButton style={{ marginRight:2 }} edge="end" aria-label="delete" onClick={() => handleDeleteRespondent(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Card>
                                    </ListItem>
                                ))}
                            </List>
                            <Button variant="contained" color="primary" onClick={handleNext}>
                                Next
                            </Button>
                        </Paper>

                    </>
                )
            }



        </Container>
    );
};

export default TestTakersComponent;
