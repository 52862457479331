import React, { useState } from 'react';
import { 
  Box,
  Paper,
  Typography,
  Button,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse, AxiosProgressEvent } from 'axios';
import { uploadBulkRegistration } from '../../API/services/BatchService';


const Input = styled('input')({
  display: 'none',
});

interface BulkRegistrationUploadProps {
  userId: string;
  assessmentId: string;
}

interface OperationResponse {
  operationId: string;
  message: string;
}

const BulkRegistrationUpload: React.FC<BulkRegistrationUploadProps> = ({ userId, assessmentId }) => {
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [operationResponse, setOperationResponse] = useState<OperationResponse | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      setUploadProgress(0);
    }
  };

  const handleUpload = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!file) {
      alert('Please select a CSV file');
      return;
    }

    try {
      const response: AxiosResponse<OperationResponse> = await uploadBulkRegistration(
        file,userId,
        assessmentId,
       
        (progressEvent: AxiosProgressEvent) => {
          if (progressEvent.total) {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(progress);
          }
        }
      );
      console.log('Upload successful:', response.data);
      setOperationResponse(response.data);
      setDialogOpen(true);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleRedirect = () => {
    navigate('/batch-job-list'); // update this route as needed
  };

  return (
    <Paper elevation={3} sx={{ p: 4, maxWidth: 600, margin: 'auto', mt: 8 }}>
      <Typography variant="h5" align="center" gutterBottom>
        Bulk Upload CSV File
      </Typography>
      <Box component="form" onSubmit={handleUpload}>
        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <label htmlFor="csv-file">
            <Input accept=".csv" id="csv-file" type="file" onChange={handleFileChange} />
            <Button variant="outlined" component="span">
              Choose File
            </Button>
          </label>
          {file && (
            <Typography variant="body1" sx={{ ml: 2 }}>
              {file.name}
            </Typography>
          )}
        </Box>
        {uploadProgress > 0 && (
          <Box mb={2}>
            <LinearProgress variant="determinate" value={uploadProgress} />
            <Typography variant="body2" align="center">
              {uploadProgress}%
            </Typography>
          </Box>
        )}
        <Box display="flex" justifyContent="center">
          <Button type="submit" variant="contained" color="primary">
            Upload CSV
          </Button>
        </Box>
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        PaperProps={{ sx: { p: 3, borderRadius: 2, maxWidth: 600, mx: 'auto' } }}
      >
        <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
          Upload Status
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center', my: 2 }}>
          {operationResponse && (
            <Box>
                <Typography variant="h6">
               {operationResponse.message} with
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                Operation ID: {operationResponse.operationId}
              </Typography>
             
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={handleRedirect} variant="contained" color="primary">
            View Batch Jobs
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default BulkRegistrationUpload;
