import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Container,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import { AxiosResponse } from 'axios';
import { getBatchJobList, getBatchJobStatus } from '../../API/services/BatchService';
import TitleBanner from '../TitleBanner/TitleBanner';

// Define TypeScript interfaces for the API responses
interface BatchJob {
  operationId: string;
  failed: number;
  total: number;
  processing: number;
  pending: number;
  success: number;
}

interface BatchJobDetail {
  id: string;
  operationId: string;
  email: string;
  firstname: string;
  lastname: string;
  userId: string;
  assessmentId: string;
  status: string;
  message: string;
  createdDateTime: string;
  lastUpdatedDateTime: string;
}

// Create a styled TableCell for status, with a background color depending on the status value.
interface StatusCellProps {
  status: string;
}
const StatusCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'status',
})<StatusCellProps>(({ status }) => ({
  backgroundColor:
    status === 'SUCCESS'
      ? '#d0f0c0'
      : status === 'FAILED'
      ? '#f0d0d0'
      : 'inherit',
}));
// A common style object for table cells to wrap content
const cellStyle = { wordBreak: 'break-word', whiteSpace: 'normal' };

const BatchJobList: React.FC = () => {
  const [batchJobs, setBatchJobs] = useState<BatchJob[]>([]);
  const [details, setDetails] = useState<BatchJobDetail[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedOperationId, setSelectedOperationId] = useState<string | null>(null);

  // Fetch batch job list on component mount
  useEffect(() => {
    const fetchBatchJobs = async () => {
      try {
        const response: AxiosResponse<BatchJob[]> = await getBatchJobList();
        setBatchJobs(response.data);
      } catch (error) {
        console.error('Error fetching batch jobs', error);
      }
    };
    fetchBatchJobs();
  }, []);

  // When the user clicks the three-dots, fetch details for that operationId and open the dialog
  const handleViewDetails = async (operationId: string) => {
    setSelectedOperationId(operationId);
    try {
      const response: AxiosResponse<BatchJobDetail[]> = await getBatchJobStatus(operationId);
      setDetails(response.data);
      setDialogOpen(true);
    } catch (error) {
      console.error('Error fetching batch job details', error);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDetails([]);
  };

  return (
    <Container>
        <TitleBanner title={"Batch Job List"}  />
   
    <Box sx={{ p: 2, width: '100%' }}>
      <TableContainer component={Paper}
        sx={{ width: '100%', overflowX: 'visible' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Operation ID</TableCell>
              <TableCell align="right">Failed</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell align="right">Processing</TableCell>
              <TableCell align="right">Pending</TableCell>
              <TableCell align="right">Success</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {batchJobs.map((job) => (
              <TableRow key={job.operationId}>
                <TableCell>{job.operationId}</TableCell>
                <TableCell align="right">{job.failed}</TableCell>
                <TableCell align="right">{job.total}</TableCell>
                <TableCell align="right">{job.processing}</TableCell>
                <TableCell align="right">{job.pending}</TableCell>
                <TableCell align="right">{job.success}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => handleViewDetails(job.operationId)}>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog to show detailed view for a specific batch job */}
      <Dialog
  open={dialogOpen}
  onClose={handleCloseDialog}
  fullWidth
  PaperProps={{
    sx: {
      width: '100vw',
      maxWidth: '100vw',
      m: 0,
      p: 3,
      borderRadius: 0,
      overflowX: 'visible',
    },
  }}
>
  <DialogTitle
    sx={{
      textAlign: 'center',
      fontWeight: 'bold',
      borderBottom: '1px solid #ddd',
      mb: 2,
      pb: 1,
    }}
  >
    Batch Job Details {selectedOperationId && `- ${selectedOperationId}`}
  </DialogTitle>
  <DialogContent sx={{ overflowX: 'visible' }}>
    <TableContainer component={Paper} sx={{ width: '100%', overflowX: 'visible' }}>
      <Table size="small" sx={{ tableLayout: 'auto' }} aria-label="batch job details table">
        <TableHead>
          <TableRow>
            <TableCell sx={cellStyle}>ID</TableCell>
            <TableCell sx={cellStyle}>Operation ID</TableCell>
            <TableCell sx={cellStyle}>Email</TableCell>
            <TableCell sx={cellStyle}>First Name</TableCell>
            <TableCell sx={cellStyle}>Last Name</TableCell>
            <TableCell sx={cellStyle}>User ID</TableCell>
            <TableCell sx={cellStyle}>Assessment ID</TableCell>
            <TableCell sx={cellStyle}>Status</TableCell>
            <TableCell sx={cellStyle}>Message</TableCell>
            <TableCell sx={cellStyle}>Created Date</TableCell>
            <TableCell sx={cellStyle}>Last Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {details.map((detail) => (
            <TableRow key={detail.id}>
              <TableCell sx={cellStyle}>{detail.id}</TableCell>
              <TableCell sx={cellStyle}>{detail.operationId}</TableCell>
              <TableCell sx={cellStyle}>{detail.email}</TableCell>
              <TableCell sx={cellStyle}>{detail.firstname}</TableCell>
              <TableCell sx={cellStyle}>{detail.lastname}</TableCell>
              <TableCell sx={cellStyle}>{detail.userId}</TableCell>
              <TableCell sx={cellStyle}>{detail.assessmentId}</TableCell>
              <StatusCell status={detail.status}>{detail.status}</StatusCell>
              <TableCell sx={cellStyle}>{detail.message}</TableCell>
              <TableCell sx={cellStyle}>
                {new Date(detail.createdDateTime).toLocaleString()}
              </TableCell>
              <TableCell sx={cellStyle}>
                {new Date(detail.lastUpdatedDateTime).toLocaleString()}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </DialogContent>
  <DialogActions sx={{ justifyContent: 'center', mt: 2 }}>
    <Button onClick={handleCloseDialog} variant="contained" color="primary">
      Close
    </Button>
  </DialogActions>
</Dialog>


    </Box>
    </Container>
  );
};

export default BatchJobList;
