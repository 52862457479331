import { Card, Tooltip, Typography, Box } from "@mui/material";
import { fNumber } from "../../../utils/numberFormater";

interface ScoreCardType {
  title: string;
  minimum?: number;
  total: number;
  result?: boolean;
  source?: string|null; // Added source
}

// Source Descriptions
const sourceDescriptions: { [key: string]: string } = {
  DEFAULT: "Default",
  ONET: "ONET",
  ADMIN: "Admin",
};

const ScoreCard = ({ title, total, result = true, minimum, source }: ScoreCardType) => {
  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: "center",
        bgcolor: result ? "#D1E9FC" : "#f5aca4",
        color: "#061B64",
        flexGrow: 1,
        position: "relative",
      }}
    >
      {/* Display Source Name in Top-Right (Only if source is NOT NULL) */}
      {source && source !== "NULL" && sourceDescriptions[source] && (
        <Tooltip title={sourceDescriptions[source]} arrow>
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              fontSize: "0.6rem",
              fontWeight: "bold",
              color: "#333",
              cursor: "pointer",
            }}
          >
            {sourceDescriptions[source]}
          </Box>
        </Tooltip>
      )}

      <Typography variant="h3">{total}</Typography>

      {minimum && (
        <Typography variant="caption" sx={{ opacity: 0.72 }}>
          Minimum: {minimum}
        </Typography>
      )}

      <Tooltip title={`${title} - ${result ? "Passed" : "Failed"}`} arrow>
        <Typography
          fontWeight={600}
          variant="subtitle2"
          sx={{
            opacity: 0.72,
            width: "50%",
            margin: "0 auto",
            textAlign: "center",
            whiteSpace: "wrap",
            cursor: "pointer",
          }}
        >
          {title}
        </Typography>
      </Tooltip>
    </Card>
  );
};

export default ScoreCard;
