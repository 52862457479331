import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { getAdministratorsByOrgID, transferOwnership } from "../../API/services";
import { useNotificationStore } from "../../store/NotificationStore";
import { success } from "../Notification/NotificationMessages";
import { getErrorMessage } from "../../utils/util";

interface Admin {
  firstName: string;
  id: string;
  name: string;
}

interface TransferOwnershipDialogProps {
  open: boolean;
  organizationId: string;
  onClose: () => void;
}

const TransferOwnershipDialog: React.FC<TransferOwnershipDialogProps> = ({ open, organizationId, onClose }) => {
    const setNotification = useNotificationStore(
        (state) => state.setNotification
      );
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [selectedAdmin, setSelectedAdmin] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  // Fetch administrators manually using useEffect
    useEffect(() => {
        const fetchAdminListings = async () => {
            if (open) {
                setLoading(true);
                try {
                    const response = await getAdministratorsByOrgID(organizationId);
                    setAdmins(response.data);
                    setLoading(false);
                } catch (error) {
                    console.error("Error fetching admin listings:", error);
                    setError("Failed to fetch administrators");
                    setLoading(false);
                }
            }
        };
        fetchAdminListings();
        
    }, [open]); // Fetch data only when dialog opens

  const handleSubmit = async() => {
    if (!selectedAdmin) return;
    try {
        await transferOwnership(selectedAdmin)
     onClose();
        setNotification(
            success("Ownership transferred successfully")
          );
    } catch (error) {
        setNotification(
            success(getErrorMessage(error))
          );
    }
     
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Transfer Account Ownership</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>Select Administrator</InputLabel>
          <Select
            value={selectedAdmin}
            onChange={(e) => setSelectedAdmin(e.target.value as string)}
            disabled={loading}
          >
            {loading ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : error ? (
              <MenuItem disabled>{error}</MenuItem>
            ) : (
              admins.map((admin) => (
                <MenuItem key={admin.id} value={admin.id}>
                  {admin.firstName}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={!selectedAdmin}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferOwnershipDialog;
