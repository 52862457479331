// RespondentsEntry.tsx
import React, { useState } from 'react';
import { Container, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import TestTakersComponent from './assessmentTakers';
import BulkRegistrationUpload from '../../BulkUpload/BulkRegistrationUpload';
import TitleBanner from '../../TitleBanner/TitleBanner';
import { getLoggedInUserID } from '../../../utils/util';
import DownloadButton from '../../DownloadButton/DownloadButton';

const RespondentsEntry: React.FC = () => {
  const location = useLocation();
  const assessmentId = location.state?.assessmentId;
  // In a real app, you might get the userId from authentication context or similar.
//   const userId = 'A4B0BEE3-4B71-4845-A171-48AF67EFDD1C';

  const userID= getLoggedInUserID();

  const [entryMethod, setEntryMethod] = useState<'manual' | 'bulk'>('manual');

  const handleEntryMethodChange = (
    event: React.MouseEvent<HTMLElement>,
    newMethod: 'manual' | 'bulk' | null
  ) => {
    if (newMethod !== null) {
      setEntryMethod(newMethod);
    }
  };

  return (
    <Container>
    <TitleBanner title={"Add / Upload - Respondents | Applicants"}  rightChild={<DownloadButton/>}/>
      <ToggleButtonGroup
        value={entryMethod}
        exclusive
        onChange={handleEntryMethodChange}
        aria-label="Entry method"
        sx={{ mt: 2, mb: 2 }}
      >
        <ToggleButton value="manual" aria-label="Manual Entry">
          Manual Entry
        </ToggleButton>
        <ToggleButton value="bulk" aria-label="Bulk Upload">
          Bulk Upload
        </ToggleButton>
      </ToggleButtonGroup>

      {entryMethod === 'manual' && <TestTakersComponent />}
      {entryMethod === 'bulk' && assessmentId && userID && (
        <BulkRegistrationUpload userId={userID} assessmentId={assessmentId} />
      )}
    </Container>
  );
};

export default RespondentsEntry;
